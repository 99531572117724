import React, { useEffect, useLayoutEffect, useRef } from "react"
// import { useForm } from "react-hook-form"
// import * as css from "@css/projects/p-form.module.styl"
import { useRecoilState } from 'recoil'
import { userLoginState } from "@status/UserState"
import { isBrowser } from "../../services/auth"
import { useTransition } from "../layouts/TransitionLayout"


{/* 埋め込みコード
<button class="button pia-ticket"
    data-url="https://va.pia.jp/XXXXX/entrance.jsp"
    data-acptno="XXXXXXXXXXXXXXXX"
    data-slcd="VXXXX"
>
    お申し込みはこちらA
</button>
*/}


export default function EmbedTicket({
    isButton
}){
    const return_url = "https://fcrabupi.shirota-yu.com/500"
    const formRef = useRef()
    // const [crypto, setCrypto] = useState()
    // const [actionUrl, setActionUrl] = useState()
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    const { setTranstionState } = useTransition()
    
    useLayoutEffect( () => {
        console.log("btn=>","setup");
        // ( async()=>{
        //     if( !isBrowser() )return
        //     const buttons = document.getElementsByClassName('ticket-api')
        //     for(let i = 0; i < buttons.length; i ++){
        //         let btn:any = buttons[i]
        //         // btn.classList.add("button-disable")
        //     }
        //     // let user = await getFcUser()
        //     // if( !user ){
        //     //     return
        //     // }
        //     // setCrypto(user.crypto)
        // })()
    },[userLogin])

    useEffect( ()=>{
        if( !isBrowser() || !userLogin ) return
        ( async ()=>{
            const buttons = document.getElementsByClassName('pia-ticket')
            for(let i = 0; i < buttons.length; i ++){
                let btn = buttons[i]
                btn.classList.remove("button-disable")
                btn.classList.add("button")
                btn.addEventListener('click',(e)=>{
                    e.preventDefault();
                    ( async ()=>{
                        const data = e.target.dataset;
                        document.getElementById("acpt_no").value = data.acptno;
                        document.getElementById("slcd").value = data.slcd;
                        formRef.current.action = btn.dataset.url;
                        formRef.current.target = 'new_window'
                        formRef.current.submit();
                    } )()

                })
            }
        } )()
        console.log("btn=>","ok")
    },[userLogin])

    return(
        <>
        {/* <meta http-equiv="content-type" content="text/html; charset=Shift-JIS"> */}
        {/* <form name="cnplayguide" ref={formRef} method="POST" acceptCharset="Shift_JIS">
            <input type="hidden" name="keyword" id="keyword"/>
            <input type="hidden" name="identifier" id="identifier" value={identifier}/>
            { isButton && <input type="submit" name="pre_verification" id="pre_verification" value="チケットを購入" /> }
        </form> */}
        <form action="https://va.pia.jp/XXXXX/entrance.jsp" method="POST" ref={formRef}>
            <input type="hidden" name="acpt_no" id="acpt_no"/>
            <input type="hidden" name="slcd" id="slcd"/>
            {/* <input type="submit" value="次へ"/> */}
        </form>
        </>
    )
}
