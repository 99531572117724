import React, { useEffect, useRef, useState } from "react"
// import { useForm } from "react-hook-form"
import * as css from "@css/projects/p-form.module.styl"
// import { Box} from '@mui/system';
// import { Input, Select, MenuItem, TextField, FormGroup } from '@mui/material';
// import { randomString } from "@utility/Crypto";
// import { TripleDES, mode, pad, enc } from "crypto-js";
// import { useForm } from "react-hook-form";
// import qs from 'qs'
// import axios from 'axios'
import { isBrowser } from "@services/auth";
import { getFcUser } from "@src/services/auth.js"
// import btoa from 'btoa'
// import atob from 'atob'
// import dayjs from "dayjs";
// import {useEventListener} from "@src/hooks/useEventListener";
import { useRecoilState } from 'recoil'
import { userLoginState } from "@status/UserState"


export default function EmbedLawson({isButton})
{
    // const testUrl = "https://l-tike.com/st1/ngpngxxzsv5tgalrxbvq"
    // const site_id = "0000032479"
    const site_id = useRef()
    const return_url = "https://fcrabupi.shirota-yu.com/500"
    // const return_url = "http://117.102.202.248:8000/"
    // const return_url = "http://localhost:8000/member/"
    const formRef = useRef()
    const [crypto, setCrypto] = useState()
    const [actionUrl, setActionUrl] = useState()
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    
    useEffect( async()=>{
        if( !isBrowser() )return
        const buttons = document.getElementsByClassName('lawson-api')
        for(let i = 0; i < buttons.length; i ++){
            let btn = buttons[i]
            btn.style.opacity = 0.3
        }
        // let user = await getFcUser()
        // if( !user ){
        //     return
        // }
        // setCrypto(user.crypto)
    },[userLogin])

    useEffect( ()=>{
        if( !isBrowser() || !userLogin ) return
        console.log('test law2')
        const buttons = document.getElementsByClassName('lawson-api')
        for(let i = 0; i < buttons.length; i ++){
            let btn = buttons[i]
            btn.style.opacity = 1.0

            btn.addEventListener('click', async (e)=>{
                // e.preventDefault()
                let user = await getFcUser()
                // setCrypto(user.crypto)
                // setActionUrl(btn.dataset.url)
                // console.log("user->",user)
                for( let key of Object.keys(user.crypto) ){
                    let e = document.getElementById(key)
                    if( e ){
                        e.value = user.crypto[key]
                        // console.log(`key-> ${key}`, e.value )
                        // console.log(e.value )
                        // console.log(encodeURI(e.value))
                    }
                }
                document.getElementById("STID").value = btn.dataset.siteid
                formRef.current.action = btn.dataset.url
                // formRef.current.target = 'new_window'
                formRef.current.submit()
            })
        }
    },[userLogin])

    // useEffect(()=>{
    //     if(actionUrl && crypto){
    //         formRef.current.action = actionUrl
    //         formRef.current.submit()
    //         console.log('submit')
    //         setActionUrl(null)
    //         setCrypto(null)
    //     }
    // },[actionUrl])

    return(
        <form ref={formRef} method="POST" acceptCharset="Shift_JIS">
            <input type="hidden" name="DBNID" value={"1"}/>
            <input type="hidden" name="ALCD" value={"1"} />
            <input type="hidden" name="STID" id="STID" value={site_id.current} />
            <input type="hidden" name="RTN_URL" value={return_url} />
            <input type="hidden" id="TIME_STAMP" name="TIME_STAMP"/>
            <input type="hidden" id="MEMBR_NUM"  name="MEMBR_NUM" />
            <input type="hidden" id="MAIL_ADDR" name="MAIL_ADDR" />
            <input type="hidden" id="NM_KANA_S" name="NM_KANA_S" />
            <input type="hidden" id="NM_KANA_M" name="NM_KANA_M" />
            <input type="hidden" id="NM_KANJI_S" name="NM_KANJI_S" />
            <input type="hidden" id="NM_KANJI_M" name="NM_KANJI_M" />
            <input type="hidden" id="TEL_NO" name="TEL_NO" />
            <input type="hidden" id="ZIPCD" name="ZIPCD" />
            <input type="hidden" id="ADDR_1" name="ADDR_1"/>
            <input type="hidden" id="ADDR_2" name="ADDR_2" />
            <input type="hidden" id="ADDR_3" name="ADDR_3" />
            <input type="hidden" id="ADDR_4" name="ADDR_4" />
            { isButton && <input type="submit" value="ログインしてチケットを購入" /> }
        </form>
    )
}
