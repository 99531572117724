import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionColumn, SectionMoreLink, SectionIframe } from "@layouts/Section"
import Iframe from "react-iframe"
import EmbedLawson from "@blocks/EmbedLawson"
import EmbedTicket from "@blocks/EmbedTicket"
import { PageState } from '@status/PageState'
import { useRecoilState } from "recoil"
import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

// import { Router } from "@reach/router"
// import PrivateRoute from "@projects/PrivateRoute"

import * as css from "@css/layouts/l-article.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

function FreePage({data})
{
    console.log(data)
    const { news } = data
    const { slug, title, date, content, thumbnail, acf_freepage} = news
    const { iframeUrl, vimeoChatUrl, isRobotIndex, isHideHeader, isMemberOnly } = acf_freepage
    // let vimeoChatUrl = 'https://vimeo.com/event/1277831/chat/'
    // const img = getImage(thumbnail.gatsbyImageData)
    // const contentRef = React.useRef()
    const [pageState, setPageState] = useRecoilState(PageState)

    useEffect(()=>{
        setPageState({isHideHeader})
    },[])

    const dom = () => {
        return(
            <>
            <SEO
                subtitle={title}
                isHideHeader={isHideHeader}
                // subtitle={!isHideHeader && title}
                // description={excerpt}
                image={thumbnail && `${thumbnail.node.localFile.publicURL}`}
                noIndex={isRobotIndex}
            />
            <EmbedLawson/>
            <EmbedTicket/>
            { !pageState.isHideHeader && <Spacer.Header/> }
            <Section>
                {/* <SectionHeader
                    title="News"
                    text="ニュース"
                    isBorder={false}
                /> */}
                <article className={css.lArticle}>
                    <div className={css.lArticle__inner}>
                        <header className={css.lArticle__header}>
                            {/* <p className={css.aDate}><time dateTime={date}>{date}</time></p> */}
                            <h1 className={css.aTitle}>{title}</h1>
                        </header>
                        { thumbnail &&
                            <figure className={css.lArticle__thumbnail}>
                                <Image data={thumbnail.node.localFile} alt="thubmanil" />
                            </figure>
                        }
                        <div className={css.lArticle__content} dangerouslySetInnerHTML={{ __html: content }} />
                        {iframeUrl &&
                            <div className={css.aIframe} style={{ marginTop: 30 }}>
                                <Iframe src={iframeUrl} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen/>
                            </div>
                        }
                        {/* {vimeoChatUrl &&
                            <div style={{ marginTop: 30 }}>
                            <SectionIframe>
                                <Iframe src={vimeoChatUrl} scrolling={false} />
                            </SectionIframe>
                            </div>
                        } */}
                        { vimeoChatUrl &&
                            <div className={css.aIframeChat} style={{ marginTop: 30 }}>
                                <Iframe src={`${vimeoChatUrl}`} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen/>
                            </div>
                        }
                    </div>
                </article>
                { !pageState.isHideHeader &&
                    <SectionMoreLink
                        title="BACK"
                        text="トップ"
                        to="/"
                        isBorder={false}
                    />
                }
            </Section>
        </>
        )
    }

    return(
        <>
            { isMemberOnly ?
                <Router basepath="/">
                    <PrivateRoute path={`${slug}`} component={dom}/>
                </Router>
                :dom()
            }
        </>  
    )
}

export const pagerQuery = graphql`
    fragment PageThumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment Page on WpPage {
        acf_freepage {
            iframeUrl
            vimeoChatUrl
            isRobotIndex
            isMemberOnly
            isHideHeader
        }
        slug
        date(formatString: "YYYY.MM.DD")
        title
        content
        uri
        databaseId
        
        thumbnail: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 1280
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            quality: 100
                        )
                    }
                }
            }
        }
    }
    query FreePageBySlug($slug: String!){
        news : wpPage( slug: { eq: $slug }){
            ...Page
        }
    }
`
export default FreePage